.medium-slider {
  padding: 0 4rem;
  padding-bottom: 0.5rem;
  overflow-y: hidden;

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;  // IE and Edge
  scrollbar-width: none;  // Firefox

  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  .wrapper {
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .slide {
    padding: 1rem;
    color: rgba(0 0 0 / 70%);
    height: 100%;
    overflow-y: hidden;

    .title {
      margin-bottom: 1rem;
      text-align: center;
      font-size: 1.1rem;
      font-weight: 500;
      height: 2rem;
    }

    .text {
      height: 90%;
      text-align: justify;
      overflow-y: auto;
      margin-bottom: 1rem;
      white-space: pre-line;
    }
  }
}
