/**
 * A simple set of CSS rules that resets the styling
 * of all HTML elements to a consistent baseline.
 */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%; /* 1rem = 16px */
}

body {
  font-size: 1rem; /* 16px */
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}
