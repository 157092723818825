$verse-card-background: #fafafa;
$verse-card-selected: #e0e0e0;

.verse-item-body {
  height: 100%;

  .clickable {
    height: 100%;
    cursor: pointer;

    &:hover {
      background-color: $verse-card-selected;
    }

    &:active {
      background-color: $verse-card-selected;
    }

    &:focus {
      background-color: $verse-card-selected;
    }
  }

  .unclickable {
    height: 100%;
    cursor: auto;
    font-size: 1rem;
  }

  .unclickable-title {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 0.2rem;
    color: rgba(0 0 0 / 90%);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .title {
    font-weight: 500;
    color: rgba(0 0 0 / 90%);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .text {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
