$verse-card-background: #fafafa;
$verse-card-selected: #e0e0e0;

.verse-card-body {
  height: 100%;

  .clickable {
    height: 100%;
    cursor: pointer;
    background-image: linear-gradient(rgba(0 0 0 / 5%), rgba(0 0 0 / 1%));

    &:hover {
      background-color: $verse-card-selected;
    }

    &:active {
      background-color: $verse-card-selected;
    }

    &:focus {
      background-color: $verse-card-selected;
    }
  }

  .unclickable {
    height: 100%;
    padding: 1rem;
    cursor: auto;
    background-image: linear-gradient(rgba(0 0 0 / 5%), rgba(0 0 0 / 1%));
    font-size: 1rem;
  }

  .unclickable-title {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 1rem;
    color: rgba(0 0 0 / 90%);
  }

  .title {
    font-weight: 500;
    color: rgba(0 0 0 / 90%);
  }
}
