$background-default: rgba(0 0 0 / 90%);
$background-selected: rgba(0 0 0 / 70%);

.container {
  button {
    padding: 0.8rem;
    border-radius: 1.5rem;
    font-size: 1rem;
    background-color: $background-default;
    color: rgba(255 255 255 / 90%);
    text-transform: none;
    min-width: 9rem;

    &:disabled {
      background-color: rgba(0 0 0 / 70%);
      color: rgba(255 255 255 / 60%);
    }

    &:hover {
      background-color: $background-selected;
    }

    &:active {
      background-color: $background-selected;
    }

    &:focus {
      background-color: $background-selected;
    }
  }
}
