$overlay-color: rgba(0 0 0 / 15%);
$box-shadow-blur: 10px; // Adjust this value to control the blur amount
$blur-amount: 2px;

.text-box {
  z-index: 2;
  padding: 1rem;
  box-shadow: 0 0 $box-shadow-blur rgba(0 0 0 / 30%);
  border-radius: 0.5rem;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 0.5rem;
    background: $overlay-color; // Semi-transparent overlay
    z-index: -1;
    /* backdrop-filter: blur($blur-amount); */ // Optional: adds a blur effect
  }
}
