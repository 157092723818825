.text-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding-left: 2rem;
  padding-right: 4rem;
  padding-top: 1.5rem;
  color: rgba(0 0 0 / 60%);
  background: transparent;
  pointer-events: none; /* Ensure the overlay doesn't block mouse events */
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 1.5s ease-in-out, opacity 1s ease-in-out;
}

.text-slide.active {
  opacity: 1;
  transform: translateY(0);
}

.text-slide.down {
  transform: translateY(100%);
  opacity: 0;
}
