.excerpts {
  padding-bottom: 1rem;
  height: 100%;

  .excerpts-card {
    background-color: rgba(0 0 0 / 3%);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
