$background-default: #fafafa;
$background-selected: #e0e0e0;

.root {
  display: flex;
  align-items: center;
  border-radius: 5px;
  min-width: 16rem;
  max-width: 16rem;

  .show {
    display: flex;
  }

  .hide {
    display: none;
  }

  .default {
    background-color: $background-default;
  }

  .selected {
    background-color: $background-selected;
  }

  .default, .selected {
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    min-width: 16rem;
    max-width: 16rem;
  }

  &:hover {
    background-color: $background-selected;
  }

  &:active {
    background-color: $background-selected;
  }

  &:focus {
    background-color: $background-selected;
  }

  button {
    &:hover {
      background-color: $background-selected;
    }

    &:active {
      background-color: $background-selected;
    }

    &:focus {
      background-color: $background-selected;
    }
  }

  .page {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    font-weight: 400;
  }

  .menushow {
    display: flex;
  }

  .menuhide {
    display: none;
  }

  .menu {
    min-width: 0;
    justify-content: flex-end;
    align-items: center;
    font-weight: 900;
  }

  .page, .menu {
    color: rgba(0 0 0 / 87%);
    font-size: 1rem;
    text-transform: none;

    .fading {
      display: flex;
      justify-content: flex-start;
      color: rgba(0 0 0 / 80%);
      // stylelint-disable-next-line property-no-vendor-prefix
      -webkit-mask-image: linear-gradient(to right, rgba(0 0 0 / 80%) 90%, transparent 100%);
      mask-image: linear-gradient(to right, rgba(0 0 0 / 80%) 90%, transparent 100%);
      width: 100%;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-transform: none;
    }
  }
}
