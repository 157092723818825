.carousel-wrapper {
  height: calc(100% - 64px);
  width: 100%;
  background-color: #f5f5f5;
  padding-bottom: 0.3rem;
}

@media (max-width: 900px) {
  .carousel-wrapper {
    height: calc(100% - 96px);
  }
}

@media (max-width: 516px) {
  .carousel-wrapper {
    height: calc(100% - 120px);
  }
}
