// stylelint-disable-next-line selector-class-pattern
.textarea_container {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem;
  border-radius: 1.5rem;
  border: 1px solid rgba(0 0 0 / 30%);

  &:focus-within {
    border: 1px solid rgba(0 0 0 / 40%);
  }

  textarea {
    padding: 0.5rem;
    width: 100%;
    height: 100%;
    resize: none;
    color: rgba(0 0 0 / 70%);
    font-size: 1rem;
    font-weight: 400;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    border: 0;

    &:focus {
      outline: none;
      border: 0;
    }
  }
}
