$user-message-background: #e0e0e0;

.message-body {
  background-color: $user-message-background;
  border-radius: 1rem;
  padding: 1rem;
  white-space: pre-line;
  position: relative;
  font-size: 1rem;
}
