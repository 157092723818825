.shared-link {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 1.1rem;
  max-width: 24rem;
  min-width: 12rem;
  overflow: hidden;

  .fading {
    display: flex;
    justify-content: flex-start;
    color: rgba(0 0 0 / 60%);
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-mask-image: linear-gradient(to right, rgba(0 0 0 / 60%) 90%, transparent 100%);
    mask-image: linear-gradient(to right, rgba(0 0 0 / 60%) 90%, transparent 100%);
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-transform: none;
  }
}
